<template>
  <div>
    <v-layout wrap justify-start>
      <v-flex xs12 sm12 md4 lg2 xl2 text-left pb-2 pb-md-0>
        <v-btn block medium tile outlined color="#FF0000">
          <span
            style="font-family: poppinsmedium; font-size: 15px; color: #000000"
          >
            {{ storage.theme.name }}
          </span>
        </v-btn>
      </v-flex>
      <v-flex xs4 sm2 md4 lg7 align-self-end text-right>
        <v-dialog v-model="approveDialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" outlined small color="green"
              >Approve</v-btn
            >
          </template>
          <v-card>
            <v-card-title
              >Are you sure you want to approve this Theme?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="approveDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                color="blue darken-1"
                :disabled="!approve"
                text
                @click="approve(storage.theme._id)"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
      <v-flex xs4 sm2 md1 lg1 align-self-end text-right>
        <v-dialog v-model="rejectDialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              small
              v-on="on"
              v-bind="attrs"
              color="red"
              @click="rejectDialog = true"
            >
              Reject
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline grey lighten-2">
              Reject Confirmation
            </v-card-title>
            <v-card-text>
              <span style="font-size: 16px; font-weight: 600"
                >Please confirm that you want to Remove this Theme?
              </span>
            </v-card-text>
            <v-divider></v-divider>
            <v-flex pt-5 pa-5>
              <v-text-field
                v-model="reason"
                label="Reason"
                dense
                outlined
              ></v-text-field>
            </v-flex>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="rejectDialog = false">
                Cancel
              </v-btn>
              <v-btn color="error" text class="mr-4" @click="reject(storage.theme._id)">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
      <v-flex xs6 sm6 md4 lg1 align-self-end text-right>
        <v-btn
          outlined
          small
          color="green"
          @click="editCategoryDialog(storage)"
        >
          <span>Edit</span>
        </v-btn>
      </v-flex>
      <v-flex xs6 sm6 md4 lg1  align-self-end text-right>
        <v-dialog v-model="deleteDialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" small outlined color="red">
              <span>Delete</span>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              Are you sure you want to delete this Theme?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="deleteDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="deleteItem(storage.theme)"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-4>
      <v-flex xs12 sm5 md4>
        <v-img cover :src="mediaURL + storage.theme.image">  <v-layout
            wrap
            fill-height
            justify-center
            class="mainbody"
            v-if="storage.theme.addedby.type = 'Affliate'"
          >
            <v-flex xs12 sm12 md12 text-left align-self-start>
              <v-card
                tile
                flat
                style="background: rgba(0, 0, 0, 0.5) !important"
              >
                <v-layout wrap pa-4>
                  <v-flex xs12 align-self-center text-left>
                    <span
                      style="
                        font-family: poppinsmedium;
                        font-size: 20px;
                        color: #ffffff;
                      "
                    >
                      {{ storage.theme.addedby.firstname }}
                      {{ storage.theme.addedby.lastname }} 
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout></v-img>
      </v-flex>
      <v-flex xs12 sm7 md8 pa-2 align-self-center v-if="storage.data">
        <OwlCarousel :nav="false" :dots="true" :responsive="owlResponsive">
          <template xs12 v-for="(list, i) in storage.data">
            <v-card tile flat :max-width="widthOfCard" :key="i" :ripple="false">
              <v-img :src="mediaURL + list.image" height="200px" cover> </v-img>
              <v-layout wrap justify-center px-2 fill-height>
                <v-flex
                  pt-2
                  xs12
                  text-left
                  style="line-height: 16px; cursor: pointer"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 v-if="list.product.productname">
                      <span style="font-size: 12px">
                        {{ list.product.productname.slice(0, 20) }}
                        <span v-if="list.product.productname.length > 20">
                          ...
                        </span>
                      </span>
                    </v-flex>

                    <v-flex xs12>
                      <span
                        style="
                          font-family: poppinssemibold;
                          font-size: 13px;
                          color: #000000;
                        "
                      >
                        Rs. {{ list.product.price }}
                      </span>
                    </v-flex>
                    <v-flex xs12>
                      <span
                        class="pl-12 text-decoration-line-through"
                        style="
                          font-family: poppinsregular;
                          font-size: 13px;
                          color: #ff0000;
                        "
                        v-if="list.product.offer > 0"
                      >
                        Rs. {{ list.product.orginalprice }}
                      </span>
                    </v-flex>
                    <v-flex xs12>
                      <span>
                        <v-rating
                          v-model="list.product.ratingcount"
                          color="#FFD341"
                          background-color="#e0e0e0"
                          empty-icon="$ratingFull"
                          half-increments
                          readonly
                          hover
                          small
                        ></v-rating>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </template>
        </OwlCarousel>
      </v-flex>
      <v-dialog v-model="editdialog" max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Theme</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-col cols="12">
                    <v-text-field
                      v-model="storage.theme.name"
                      label="Theme Name"
                      required
                      :rules="Rules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="storage.theme.title"
                      label="Theme Title"
                      required
                      :rules="Rules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="storage.theme.caption"
                      label="Theme Caption"
                      :rules="Rules"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-flex xs12 pl-2>
                    <v-combobox
                      v-model="storage.theme.tags"
                      :items="category"
                      chips
                      clearable
                      label="Tags"
                      multiple
                      class="pt-3"
                    >
                      <template
                        v-slot:selection="{ attrs, item, select, selected }"
                      >
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="remove(item, storage.theme.tags)"
                        >
                          <strong>{{ item }}</strong
                          >&nbsp;
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-flex> -->
                  <!-- <v-col cols="12" sm="12" md="12">
                    <v-select
                      v-model="storage.shopByLookCategory"
                      :items="shopByLookCategoryArray"
                      outlined
                      item-text="name"
                      item-value="_id"
                      dense
                      label="Shop By Look Category"
                      required
                      :rules="Rules"
                    ></v-select>
                  </v-col> -->
                  <v-flex py-2 text-left pl-2 xs12 md12 lg12>
                    <span
                      style="
                        padding-left: 10px;
                        text-align: left;
                        letter-spacing: 0.54px;
                        font-size: 12px;
                      "
                      >Add Products</span
                    >
                    <v-layout wrap justify-space-between>
                      <v-flex
                        v-for="(productItem, k) in storage.data"
                        :key="k"
                        pa-2
                        xs12
                      >
                        <v-card tile outlined elevation="1">
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-layout wrap justify-end>
                                <v-flex xs1 text-right pt-1>
                                  <v-icon
                                    color="red"
                                    @click="productThemeEdit(productItem, k)"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                </v-flex>
                                <v-flex xs1 text-right pt-1>
                                  <v-icon
                                    color="red"
                                    @click="removeThemeEdit(k)"
                                  >
                                    mdi-close
                                  </v-icon>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs6 sm3 pb-5 text-left>
                              <v-img
                                :src="baseURL + productItem.image"
                                aspect-ratio="1"
                                contain
                                height="100px"
                              ></v-img>
                            </v-flex>
                            <v-flex xs6 sm9>
                              <span v-if="productItem.product.productname">
                                {{ productItem.product.productname }}
                              </span>
                              <!-- </v-flex> -->
                              <span v-else-if="productItem.productname">
                                {{ productItem.productname }}
                              </span>
                              <v-layout wrap justify-start>
                                <v-flex
                                  xs12
                                  sm6
                                  md4
                                  pa-2
                                  v-for="(list, j) in productItem.tags"
                                  :key="j"
                                >
                                  <v-chip style="cursor: pointer" outlined>
                                    {{ list }}
                                  </v-chip>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                      <v-flex pa-2>
                        <v-dialog
                          v-model="shopDialogEdit"
                          max-width="700px"
                          transition="dialog-transition"
                          persistent
                        >
                          <template v-slot:activator="{ on }">
                            <v-chip
                              v-on="on"
                              style="cursor: pointer"
                              color="red"
                              outlined
                            >
                              <span style="color: #000000">
                                <v-icon>mdi-plus</v-icon> Add Products
                              </span>
                            </v-chip>
                          </template>
                          <v-card class="pa-2">
                            <v-layout wrap>
                              <v-flex xs12 text-left>
                                <span
                                  style="
                                    padding-left: 10px;
                                    text-align: left;
                                    letter-spacing: 0.54px;
                                    font-size: 13px;
                                  "
                                  >Add Products</span
                                >
                              </v-flex>
                              <v-flex xs12>
                                <v-autocomplete
                                  v-model="editproduct"
                                  :items="productArray"
                                  outlined
                                  item-text="productname"
                                  item-value="_id"
                                  dense
                                  placeholder="Add Products"
                                  color="#FF1313"
                                  :rules="Rules"
                                >
                                  <template
                                    slot="item"
                                    slot-scope="productArray"
                                  >
                                    <v-layout wrap justify-center>
                                      <v-flex xs10 py-2>
                                        {{ productArray.item.productname }}
                                      </v-flex>

                                      <v-flex xs2 py-2>
                                        <v-img
                                          :src="
                                            baseURL +
                                            productArray.item.photos[0]
                                          "
                                          aspect-ratio="1"
                                          contain
                                          height="50px"
                                        ></v-img>
                                      </v-flex>
                                    </v-layout>
                                  </template>
                                </v-autocomplete>
                              </v-flex>
                              <v-flex xs12 v-if="editproduct">
                                <v-img
                                  :src="baseURL + curProductEdit[0].photos[0]"
                                  aspect-ratio="1"
                                  contain
                                  height="100px"
                                ></v-img>
                              </v-flex>
                              <v-flex xs12 pl-2>
                                <v-combobox
                                  v-model="storage.theme.tags"
                                  :items="category"
                                  chips
                                  clearable
                                  label="Tags"
                                  multiple
                                  class="pt-3"
                                >
                                  <template
                                    v-slot:selection="{
                                      attrs,
                                      item,
                                      select,
                                      selected,
                                    }"
                                  >
                                    <v-chip
                                      v-bind="attrs"
                                      :input-value="selected"
                                      close
                                      @click="select"
                                      @click:close="
                                        remove(item, storage.theme.tags)
                                      "
                                    >
                                      <strong>{{ item }}</strong
                                      >&nbsp;
                                    </v-chip>
                                  </template>
                                </v-combobox>
                              </v-flex>
                            </v-layout>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="shopDialogEdit = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="
                                  addProductEdit(), (shopDialogEdit = false)
                                "
                              >
                                Add
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-flex>
                      <!-- </v-layout> -->
                    </v-layout>
                    <!-- </v-chip-group> -->
                  </v-flex>
                  <v-col cols="12">
                    <v-switch
                      v-model="storage.theme.showInHomePage"
                      label="Show In Home Page"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12">
                    <ImageComp
                      :singleImage="storage.theme.image"
                      @stepper="winStepper"
                      :componentType="'mainImage'"
                      :height="'350'"
                      :width="'350'"
                      :heading="'Upload Main Image (350 * 350)'"
                    />
                    <!-- (1195 X 804) -->
                  </v-col>
                  <!-- <v-col cols="12">
                            <ImageComp
                              :singleImage="productImagePreview"
                              @stepper="winStepper"
                              :heading="'Upload Prodcut Image'"
                              :componentType="'productImage'"
                            />
                          </v-col> -->
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="editdialog = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="editTheme(storage.theme)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-dialog v-model="editdialogProduct" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Product</span>
        </v-card-title>
        <v-card-text>
          <v-layout wrap>
            <v-flex xs12 text-left>
              <span
                style="
                  padding-left: 10px;
                  text-align: left;
                  letter-spacing: 0.54px;
                  font-size: 13px;
                "
                >Add Products</span
              >
            </v-flex>
            <v-flex xs12>
              <v-autocomplete
                v-model="editingItem.product"
                :items="productArray"
                outlined
                item-text="productname"
                item-value="_id"
                dense
                @change="changePhoto(editingItem.product)"
                placeholder="Add Products"
                color="#FF1313"
                :rules="Rules"
              >
                <template slot="item" slot-scope="productArray">
                  <v-layout wrap justify-center>
                    <v-flex xs10 py-2>
                      {{ productArray.item.productname }}
                    </v-flex>

                    <v-flex xs2 py-2>
                      <v-img
                        :src="baseURL + productArray.item.photos[0]"
                        aspect-ratio="1"
                        contain
                        height="50px"
                      ></v-img>
                    </v-flex>
                  </v-layout>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs12 v-if="editingItem.image">
              <v-img
                :src="baseURL + editingItem.image"
                aspect-ratio="1"
                contain
                height="100px"
              ></v-img>
            </v-flex>
            <v-flex xs12 v-else>
              <v-img
                :src="baseURL + editingItem"
                aspect-ratio="1"
                contain
                height="100px"
              ></v-img>
            </v-flex>
            <v-flex xs12 pl-2>
              <v-combobox
                v-model="editingItem.tags"
                :items="category"
                chips
                clearable
                label="Tags"
                multiple
                class="pt-3"
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="remove(item, editingItem.tags)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
            </v-flex>
          </v-layout>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="editdialogProduct = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="newProductEdit(), (editdialogProduct = false)"
            >
              Add
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  props: ["storage", "cardWidth", "productArray", "category"],
  data() {
    return {
      widthOfCard: "250px",
      owlResponsive: {
        0: { items: 2, nav: false },
        600: { items: 2, nav: false },
        960: { items: 4, nav: false },
        1264: { items: 4, nav: false },
        1920: { items: 4, nav: false },
      },
      Rules: [(v) => !!v || "Required"],
      deleteDialog: false,
      curProduct: null,
      curProductEdit: null,
      imgId: "",
      shopDialogEdit: false,
      shopDialogProductEdit: false,
      edit: null,
      editproduct: null,
      editingitem: "",
      editdialog: false,
      file: null,
      coverFile: null,
      tagArray: [],
      mainImagePreview: "",
      productImagePreview: "",
      editdialogProduct: false,
      editingItem: "",
      image: "",
      mainImage: "",
      productImage: "",
      curInd: null,
      approveDialog: false,
      rejectDialog: false,
      reason:"",
    };
  },
  watch: {
    editproduct() {
      this.curProductEdit = null;
      var filtData = this.productArray.filter((x) => x._id == this.editproduct);
      console.log("Filt=", filtData);
      this.curProductEdit = filtData;
    },
  },
  beforeMount() {
    if (this.cardWidth) {
      this.widthOfCard = this.cardWidth;
    }
  },
  methods: {
    changePhoto(item) {
      console.log("i=", item);
      const curP = this.productArray.filter((x) => x._id == item);
      this.editingItem.image = curP[0].photos[0];
      this.editingItem.productname = curP[0].productname;
      this.editingItem.product = curP[0]._id;
      // this.editingItem.product=curP[0].productname
      console.log("pr", curP[0]);
    },
    remove(i, tags) {
      console.log("index", tags);
      const index = tags.indexOf(i);
      if (index > -1) {
        tags.splice(index, 1); // 2nd parameter means remove one item only
      }

      console.log("spliced", tags);
    },
    winStepper(window_data) {
      if (window_data.type == "mainImage") {
        this.file = window_data.selectedFiles;
      }
      //   else if (window_data.type == "productImage") {
      //     this.coverFile = window_data.selectedFiles;
      //   }
    },
    removeThemeEdit(k) {
      this.storage.data.splice(k, 1);
    },
    addProductEdit() {
      var pushThis = {};
      pushThis.productname = this.curProductEdit[0].productname;
      pushThis.product = this.curProductEdit[0]._id;
      pushThis.image = this.curProductEdit[0].photos[0];
      pushThis.tags = this.storage.theme.tags;
      this.storage.data.push(pushThis);
      // this.shopDialogEdit = false;
      console.log("pushed=", this.storage.data);
    },
    newProductEdit() {

      console.log("Ko=", this.storage.data[this.curInd]);
    },
    editCategoryDialog(item) {
      console.log("content=", item);
      this.edit = true;
      this.editing = item;
      this.editdialog = true;
      this.shopByLookCategory = item.shopByLookCategory;
      this.product = item.product;
      this.showInHomePage = item.showInHomePage;
      this.editId = item._id;
    },
    productThemeEdit(item, i) {
      this.curInd = i;
      this.editingItem = item;
      console.log("new edit", item);
      this.editdialogProduct = true;
    },
     approve(id) {
      axios({
        method: "POST",
        url: "/shopbylook/affliator/theme/approve",
        data: {
          id: id,
          approve: true,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.approveDialog = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Approved";
            this.showsnackbar = true;
            this.$emit("stepper", {
                ref: "edit",
                getData: true,
              });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    reject(id) {
      axios({
        method: "POST",
        url: "/shopbylook/affliator/theme/approve",
        data: {
          id: id,
          approve: false,
          reason: this.reason,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.rejectDialog = false;
          this.appLoading = false;
          if (response.data.status) {
            // this.getData();
            this.$emit("stepper", {
                ref: "edit",
                getData: true,
              });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editTheme(id) {
      this.addDialog = false;
      var data = {};
      data["name"] = this.storage.theme.name;
      data["title"] = this.storage.themetitle;
      data["caption"] = this.storage.theme.caption;
      data["shopByLookProducts"] = this.storage.data;
      data["showInHomePage"] = this.storage.theme.showInHomePage;
      // data["tags"] = this.storage.theme.tags;
      data["id"] = id._id;
      axios({
        url: "/shopbylook/affliator/theme/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.imgId = response.data.id;
            if (this.file) {
              this.uploadImage(id._id);
            } else {
              this.$emit("stepper", {
                ref: "edit",
                getData: true,
              });
            }
            this.msg = "Theme Added!";
            this.showsnackbar = true;
            this.editdialog = false;
            this.name = null;
            this.title = null;
            this.caption = null;
            this.shopByLookCategory = null;
            this.product = null;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(id) {
      var data = {};
      data["id"] = id._id;
      axios({
        url: "/shopbylook/affliator/theme/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Deleted Sucessfully";
            this.showsnackbar = true;
            this.deleteDialog = false;
            this.$emit("stepper", {
              ref: "edit",
              getData: true,
            });
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("photo", this.file);
      formData.append("id", id);
      axios({
        method: "POST",
        url: "/shopbylook/affliator/theme/mainimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.appLoading = false;
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.$emit("stepper", {
              ref: "edit",
              getData: true,
            });
            // this.$router.go(-1);
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
