<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Pending Theme
            <v-spacer></v-spacer>
            <!-- <v-col cols="12" sm="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                solo
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col> -->
          </v-card-title>
          <v-layout wrap justify-center v-if="user.length > 0">
            <v-flex xs12>
              <v-flex v-for="(item, i) in user" :key="i">
                <v-layout wrap justify-start py-4>
                  <v-flex xs12 sm12 md6 lg6 xl6 text-left pb-2 pb-md-0>
                    <!-- <v-btn block large tile outlined> -->
                    <span
                      style="
                        font-size: 25px;
                        color: #000000;
                        font-weight: bold;
                        letter-spacing: 3px;
                      "
                    >
                      {{ item.shopByLookCategory.name }}
                    </span>
                    <!-- </v-btn> -->
                  </v-flex>
                </v-layout>
                <!-- Products -->
                <v-layout wrap justify-start v-if="item.data">
                  <v-flex
                    v-if="item.data.length > 0"
                    xs12
                    sm12
                    md12
                    py-2
                    py-md-0
                  >
                    <v-layout wrap>
                      <v-flex xs12 pa-2 v-for="(list, i) in item.data" :key="i">
                        <ThemeItem
                          :key="i"
                          v-bind:storage="list"
                          v-bind:category="item.shopByLookCategory.tags"
                          v-bind:productArray="productArray"
                          v-bind:cardWidth="'200px'"
                          @stepper="winStepper1"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center v-else>
            <v-flex xs12 pt-10>
              <span style="font-size:20px">No Data Found</span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ThemeItem from "./themeItem";
export default {
  components: {
    ThemeItem,
  },
  data() {
    return {
      owlResponsive: {
        0: { items: 2, nav: false },
        600: { items: 2, nav: false },
        960: { items: 4, nav: false },
        1264: { items: 5, nav: false },
        1920: { items: 6, nav: false },
      },
      editing: "",
      editId: "",
      appLoading: false,
      search: "",
      msg: "",
      file: null,
      coverFile: null,
      mainImagePreview: "",
      productImagePreview: "",
      image: "",
      mainImage: "",
      productImage: "",
      currentPage: 1,
      showsnackbar: false,
      ServerError: false,
      shopDialog: false,
      dialog: false,
      addcat: false,
      shopByLookProducts: [],
      product: "",
      productArray: [],
      name: "",
      title: "",
      caption: "",
      tags: "",
      tagArray: [],
      shopByLookCategory: "",
      shopByLookCategoryArray: [],
      showInHomePage: "",
      offer: "",
      pages: 0,
      editingitem: "",
      editdialog: false,
      count: 15,
      totalData: 0,
      totalRows: 0,
      user: [],
      selectedTags: [],
      Rules: [(v) => !!v || "Required"],
      curProduct: null,
      curProductEdit: null,
      imgId: "",
      shopDialogEdit: false,
      edit: null,
      editproduct: null,
      keyword: "",
    };
  },
  mounted() {
    this.getData();
    this.getCategory();
    this.getProducts();
  },
  watch: {
    keyword() {
      this.getSearch();
    },
    currentPage() {
      this.getData();
    },
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "mainImage") {
        this.file = window_data.selectedFiles;
      }
      //   else if (window_data.type == "productImage") {
      //     this.coverFile = window_data.selectedFiles;
      //   }
    },
    winStepper1(item) {
      if (item.ref == "edit") {
        if (item.getData) {
          this.getData();
        }
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/shopbylook/affliator/theme/pendinggrouped",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        // params: {
        //   count: this.count,
        //   page: this.currentPage,
        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/shopbylook/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.shopByLookCategoryArray = response.data.data;
          for (let i = 0; i < this.shopByLookCategoryArray.length > 0; i++) {
            this.tagArray = this.shopByLookCategoryArray[i].tags;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    editCategoryDialog(item) {
      console.log("content=", item);
      this.edit = true;
      this.editing = item;
      this.editdialog = true;
      this.shopByLookCategory = item.shopByLookCategory;
      this.product = item.product;
      this.showInHomePage = item.showInHomePage;
      this.editId = item._id;
    },
    getSearch() {
      this.appLoading = true;
      axios({
        url: "/product/dropdown",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: 10,
          page: 1,
          keyword: this.keyword,
          image: true,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.productArray = response.data.data;
            this.appLoading = false;
          } else {
            this.showsnackbar = false;
            this.product = [];
            this.msg = response.data.msg;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getProducts() {
      this.appLoading = true;
      axios({
        url: "/product/dropdown",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: 0,
          page: 0,
          image: true,
          // keyword: this.keyword,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.productArray = response.data.data;
            this.appLoading = false;
          } else {
            this.showsnackbar = false;
            this.product = [];
            this.msg = response.data.msg;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    rowClick(item) {
      this.$router.push("/productDetails/" + item._id);
    },
  },
};
</script>
